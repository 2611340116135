import './App.css';
import { ToolLink } from './components/ToolLink';
import { ChainlessRoleCheck, useMsalToken } from '@chainless/chainless-auth-react'
import { useMsal } from '@azure/msal-react'
import { DocumentList } from './components/Documents';

function App() {

  const { instance, accounts } = useMsal();
  const token = useMsalToken(instance, accounts)

  return (
    <div className="App">
      <header className="App-header">
        <img src='PortalLogo.svg' className="App-logo" alt="logo" />
      </header>
      <div className='toollinks'>
        <ChainlessRoleCheck allowedRoles={['chn.software', 'chn.software', 'chn.calculatie']} account={accounts[0]}>
          <ToolLink name="ProjectTooling" displayName="Project Tooling" link="https://chainless-project-tool.dyndns.org" />
        </ChainlessRoleCheck>
        <ChainlessRoleCheck allowedRoles={['chn.dev', 'chn.software']} account={accounts[0]}>
          <ToolLink name="NaregelRapportage" displayName="Naregel Rapportage" link="https://chainless-project-tool.dyndns.org:8081" />
        </ChainlessRoleCheck>
        <ChainlessRoleCheck allowedRoles={['chn.dev', 'chn.software']} account={accounts[0]}>
          <ToolLink name="Versyon" link="https://172.35.8.49:5000" />
        </ChainlessRoleCheck>
      </div>
      { token && (
        <div className='documents'>
          <DocumentList token={token}/>
        </div>
      )}
    </div>
  );
}

export default App;
