import './ToolLink.css'

export function ToolLink({ name, displayName, link }) {
  return (
    <a className='toollink' href={link}>
      <img src={name + 'Logo.svg'} alt={name} />
      <p>{displayName || name}</p>
    </a>
  );
}
