import { callMsGraph, graphBasePath } from "@chainless/chainless-auth-react"
import { useState } from "react"
import { useEffect } from "react"

const msIdData = {
    StOFR: {
        siteId: 'chainless.sharepoint.com,ab4cea06-179a-4d21-bc8b-78e7aaf540ce,5ddc7c83-da15-4dd4-b982-b246a39b1b0e',
        driveId: 'b!BupMq5oXIU28i3jnqvVAzoN83F0V2tRNuYKyRqObGw4UwioU3BzPSKCDh7yBl1tj',
        itemId: '01I7UZ4OARNXJPJAFSI5DIZVND7BQJLXVD',
        nameMatcher: /^StOFR - Installer.*\.exe/g
    },
    ENVYSION: {
        siteId: 'chainless.sharepoint.com,f5bb22f4-0ad6-4264-9173-83860089cfad,c8d96540-53e3-42d1-bfc8-871b058f844f',
        driveId: 'b!9CK79dYKZEKRc4OGAInPrUBl2cjjU9FCv8iHGwWPhE-dBiPnHie5TKpB02H4zy-6',
        itemId: '01U2KDBETB4BVZLHUPPFHKXNUZNQM7THJX',
        nameMatcher: /^Chainless_Template_V.*\.zip/g
    }
}

function Document({ name, token }) {
    const { siteId, driveId, itemId, nameMatcher } = msIdData[name]
    const [file, setFile] = useState()

    useEffect(() => {
        const path = `${graphBasePath}/sites/${siteId}/drives/${driveId}/items/${itemId}/children`
        callMsGraph(path, token).then(d => {
            for (const f of d.value) {
                if (nameMatcher.test(f.name)) {
                    setFile({
                        name: f.name,
                        url: f['@microsoft.graph.downloadUrl']
                    })
                }
            }
        })
    }, [token, siteId, itemId, nameMatcher, driveId])

    if (file) {
        return (
            <a href={file.url}>{file.name}</a>
        )
    }
}

export function DocumentList({token}) {
    return (
        <>
            {Object.keys(msIdData).map(name => (
                <div key={name}>
                    <h2>{name}</h2>
                    <Document name={name} token={token} />
                </div>
            ))}
        </>
    )
}